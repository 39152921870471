
<!-- Activity Log table -->
<div class="activity-log-table">
  <table class="table">
    <thead>
    <tr>
      <th scope="col">Date & Time</th>
      <th scope="col">User</th>
      <th scope="col">Description</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let log of logs$ | async">
      <!-- Date & Time -->
      <td>{{log.date | date: 'YYYY-MM-dd, HH:mm a'}}</td>
      <!-- User -->
      <td>{{log.user}}</td>
      <!-- Description -->
      <td>{{log.description}}</td>
    </tr>
    </tbody>
  </table>
</div>



