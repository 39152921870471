import {Component} from '@angular/core';
import {ModelService} from "../../model/model.service";
import {LogService} from "../../common/services/log-service.service";
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {ActivityLog} from "../../model/types/activityLog";
import {TestDef} from "../../model/types/test-def";
import {Course} from "../../model/types/course";

@Component({
  selector: 'app-test-def-activity-log',
  templateUrl: './test-def-activity-log.component.html',
  styleUrls: ['./test-def-activity-log.component.scss']
})
export class TestDefActivityLogComponent {

  testDef: TestDef;
  activityLog$: Observable<ActivityLog[]>;

  constructor(private router: Router, private model: ModelService, private logger: LogService) {

    // Get the NavigationExtras (set in the component where the navigation started).
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as {
      testDef: TestDef,
    };

    this.testDef = state?.testDef;
    this.logger.debug(`==> Received test def: [${JSON.stringify(this.testDef)}]`, this.constructor.name);

    this.activityLog$ = this.model.getActivityLogsForTestDefinition(this.testDef.testDefId);
  }

  public formatCourse() {
    const courseObj: Course = this.testDef.course;
    return `${courseObj.code?.trim()} ${courseObj.sectionCode?.trim()} ${courseObj.teachMethod?.trim()}${courseObj.sectionNumber?.trim()}`;
  }
}
