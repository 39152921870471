import {Component, Input, OnChanges, SimpleChange, SimpleChanges} from '@angular/core';
import {ModelService} from "../../model/model.service";
import {LogService} from "../../common/services/log-service.service";
import {NavigationExtras, Router} from "@angular/router";
import {TestDef} from "../../model/types/test-def";

@Component({
  selector: 'app-test-def-activity-log-masthead',
  templateUrl: './test-def-activity-log-masthead.component.html',
  styleUrls: ['./test-def-activity-log-masthead.component.scss']
})
export class TestDefActivityLogMastheadComponent implements OnChanges {

  @Input()
  testDef: TestDef;

  constructor(private model: ModelService, private router: Router, private logger: LogService) {
  }

  ngOnChanges(changes: SimpleChanges): void {

    const testDef: SimpleChange = changes['testDef'];

    if (testDef && testDef.currentValue && testDef.currentValue !== testDef.previousValue) {
      this.testDef = testDef.currentValue;
    }
  }


  goToTestDefActivityLog() {

    let navigationExtras: NavigationExtras = {
      state: {
        testDef: this.testDef
      }
    };

    this.router.navigate(['/test-definition/${this.testDefId}/test-def-activity-log'], navigationExtras)
      .then(nav => {}, err => {this.logger.error("Navigation failed with error: " + err,
        `${this.constructor.name}`)});
  }

}
