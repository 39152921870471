import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModelService} from '../../../model/model.service';
import {ActivityLog} from '../../../model/types/activityLog';
import {INavigateAway} from '../../../common/types/inavigate-away';
import {ActivatedRoute} from "@angular/router";
import {StaffNotes} from "../../../model/types/staff-notes";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {LogService} from "../../../common/services/log-service.service";
import {ActivityLogType} from "../../../common/types/activity-log-type.enum";

@Component({
  selector: 'app-activity-log-and-notes-tab',
  templateUrl: './activity-log-and-notes-tab.component.html',
  styleUrls: ['./activity-log-and-notes-tab.component.scss']
})
export class ActivityLogAndNotesTabComponent implements OnInit, INavigateAway {

  @Input()
  activityLogType: ActivityLogType;

  activityLogsForTestBooking = false;

  logs: ActivityLog[];
  displayedColumns: string[] = ['date', 'user', 'description'];

  // The student test booking Id, as in the database, from the URL
  studentTestBookingId: number;

  // Data source used to create the material table
  dataSource: MatTableDataSource<ActivityLog>;

  @ViewChild(MatPaginator, {static: false})
  paginator: MatPaginator;

  // Create a form with one control (textarea), used by ATS to enter personal notes
  staffNotesForm = new UntypedFormGroup({
    note: new UntypedFormControl('')
  });

  constructor(private route: ActivatedRoute, private model: ModelService, private logger: LogService) {
  }

  ngOnInit(): void {

    // TODO: When redesigning the Manual booking details screen, remove the this.activityLogType === ActivityLogType.TEST_BOOKING
    if (this.activityLogType === ActivityLogType.TEST_BOOKING) {
      this.activityLogsForTestBooking = true;
      this.route.params.subscribe(routeParams => {
        this.studentTestBookingId = routeParams.id;

        if (this.studentTestBookingId) {
          this.model.getActivityLogsForTestBooking(this.studentTestBookingId).subscribe(res => {
            this.logs = res;
            this._initMatTableDataSource(this.logs);
          });
        }
      });
    }

  }

  /**
   * Submits the Staff Notes form to the backend.
   * As a personal staff note is associated with a given test booking,
   * get and submit the test booking Id (from the URL) as well.
   * Returns the updated activity log.
   */
  saveStaffNotes() {
    // get the note entered by user
    const note = this.staffNotesForm.get('note').value;

    // if the note is empty or has just white space do not send to the backend.
    if (!note || note.trim().length === 0) { return; }

    // build the StaffNotes object to send to the backend in the POST body
    const staffNotes = new StaffNotes(note, this.studentTestBookingId);

    // do the back-end RESt-ful call to save the data
    this.model.saveStaffNotes(staffNotes).subscribe(res => {
      this.logs = res;
      this._initMatTableDataSource(this.logs);
      this.staffNotesForm.reset();
    });
  }

  /**
   * Initializes the material table with data from back-end
   * (REST-ful call to persistent store).
   * @param dbActivityLog The activity logs.
   */
  _initMatTableDataSource(dbActivityLog: ActivityLog[]) {
    this.dataSource = new MatTableDataSource<ActivityLog>(dbActivityLog);
    this.dataSource.paginator = this.paginator;
  }

  /**
   * NavigateAway interface method.
   * Returns true if there is no unsaved data, false otherwise.
   */
  canNavigateAway(): boolean {
    return true;
  }

  updateBookingLog(): void {
    if (this.studentTestBookingId) {
      this.model.getActivityLogsForTestBooking(this.studentTestBookingId).subscribe(res => {
        this.logs = res;
        this._initMatTableDataSource(this.logs);
      });
    }
  }
}
