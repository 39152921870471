
<!-- Masthead -->
<div class="page-masthead row page-head">
  <!-- Masthead Header -->
  <div class="page-header col-8">
    <div class="masthead-header">
      <h1>Activity Log</h1>
    </div>
  </div>
  <!-- Masthead Tools -->
  <div class="page-tools col-4">
    <div class="bold">Tools</div>
    <app-student-search-masthead></app-student-search-masthead> |
    <app-provision-student-masthead></app-provision-student-masthead>
  </div>
</div>

<div>
  <!-- Course, Section -->
  <div><span class="fw-bold">Course, Section:</span>
   {{formatCourse()}}</div>
  <!-- Test Definition ID -->
  <div><span class="fw-bold">Test Definition ID:</span>
    {{testDef.testDefId}}</div>
  <!-- Clockwork Exam ID -->
  <div><span class="fw-bold">Clockwork Exam ID:</span>
    {{testDef.clockworkExamId}}</div>
</div>

<div class="col-10 margin-top-1-rem">
    <app-activity-log-table [logs$]="activityLog$"></app-activity-log-table>
</div>

<!-- Back to View & Edit Test Definition -->
<div class="margin-top-4-rem">
  <a [routerLink]="['/edit-test-def', testDef.testDefId]">Back to View & Edit Test Definition</a>
</div>
