import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {ModelService} from "../../model/model.service";
import {LogService} from "../../common/services/log-service.service";
import {MatSort} from "@angular/material/sort";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {MatTableDataSource} from "@angular/material/table";
import {AccommodationInstructions} from "../../model/types/accommodation-instructions";
import {CisSubmissionsUtils} from "../cis-submissions-utils";
import {NavigationExtras, Router} from "@angular/router";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {ViewStatisticsModalComponent} from "../view-statistics-modal/view-statistics-modal.component";
import {Statistics} from "../statistics";
import {ReviewStatusCode} from "../../common/enum/review-status-code.enum";
import {CisSubmissions} from "../cis-submissions";
import moment from "moment";
import {DateFormatConstants} from "../../common/types/dateFormatConstants";
import {ToastService} from "@easi-sis/core";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {LocalStorageService} from "../../common/services/local-storage.service";
import {AccommodationChoices} from "../../model/types/accommodation-choices";
import {AccommChoicesCacheService} from "../../common/services/accomm-choices-cache.service";
import {Subscription} from "rxjs";
import {CommonUtils} from "../../common/utils/common-utils";
import {TestDefDateTime} from "../test-def-date-time";

@Component({
  selector: 'app-cis-submissions-dashboard',
  templateUrl: './cis-submissions-dashboard.component.html',
  styleUrls: ['./cis-submissions-dashboard.component.scss']
})
export class CisSubmissionsDashboardComponent implements OnInit, OnDestroy {

  // All the columns to be displayed in the table
  displayColumns: string[] = ['atsAssignee', 'reviewedStatus', 'classWritingDate', 'classWritingStartTime',
    'classWritingEndTime', 'duration', 'courseSection', 'type', 'onlineVsInPerson', 'quercusConsent', 'testDefinitionId',
    'numberOfBookings', 'assessmentTimingType', 'lectureBeforeOfAfter', 'groupComponent', 'avCompRequired',
    'testMaterialDeliveryMethod', 'fileUploaded', 'cisSubmissionStatus', ' '];

  @Input()
  dataSource: MatTableDataSource<AccommodationInstructions> = new MatTableDataSource<AccommodationInstructions>;

  // Total number of accommodation instructions, in CIS, for given period.
  numberOfCisAccommInstructions: number;
  // The utorId of the user who logged-in ATS app
  loggedInUser: string;

  // Accommodation choices from CIS database: aids, audiovisuals, scantrons, online assessment types.
  accommodationChoices: AccommodationChoices;

  // Search start and end date controls
  searchByRangeStartDateCtrl = new FormControl('', Validators.required);
  searchByRangeEndDateCtrl = new FormControl('', Validators.required);

  todayDate = moment().format(DateFormatConstants.YYYYMMDD);

  private cacheSubscription: Subscription;

  constructor(private router: Router,
              private modalService: NgbModal,
              private toastService: ToastService,
              private model: ModelService,
              private localStorageService: LocalStorageService,
              private accommChoicesCacheService: AccommChoicesCacheService,
              private logger: LogService) {}

  // Sort and Paginator used by the Angular material table
  @ViewChild(MatSort, {static: true}) sort?: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {

    // Get accommodations choices from CIS database (aids, audiovisuals,...).
    this.cacheSubscription = this.accommChoicesCacheService.cache$.subscribe((data: AccommodationChoices) => {
      this.accommodationChoices = data;
    });

    // Try to get data from cache; if not in cache, retrieve it by doing a backend call, and cache it.
    if (this.accommChoicesCacheService.get() == null) {
      this.model.getAccommodationChoices()
        .subscribe((data: AccommodationChoices) => {
          this.accommChoicesCacheService.set(data);
        });
    }

    // Get the Date Range dates from the browser session.
    this._reinstateDateRangeFromSession();

    // Restore the user display columns order from the browser local storage.
    this._retrieveUserDisplayColumns();

    // If there is no Search Date range, set it to current day.
    if (!this.searchByRangeStartDateCtrl.value) {
      this.searchByRangeStartDateCtrl.setValue(this.todayDate);
      this.searchByRangeEndDateCtrl.setValue(this.todayDate);
    }

    // Do an initial CIS submission search, if search start and end dates are set
    this.search();

    // Monitor changing start date value and set up the same value for the end date.
    this.searchByRangeStartDateCtrl.valueChanges.subscribe( value => {
      this.searchByRangeEndDateCtrl.setValue(value);
    });

  }

  ngOnDestroy(): void {
    this._saveDateRangeInSession();

    // Save the order of the user display columns in the browser local storage.
    this._storeUserDisplayColumns();

    this.cacheSubscription.unsubscribe();
  }

  // Save the Date Range and Date Range End in the browser session.
  _saveDateRangeInSession() {
    sessionStorage.setItem("rangeStartDate", this.searchByRangeStartDateCtrl.value);
    sessionStorage.setItem("rangeEndDate", this.searchByRangeEndDateCtrl.value);
  }

  // Reinstate the Date Range and Date Range End from the browser session.
  _reinstateDateRangeFromSession() {
    const startDate = sessionStorage.getItem("rangeStartDate");
    const endDate = sessionStorage.getItem("rangeEndDate");

    if (startDate && endDate) {
      this.searchByRangeStartDateCtrl.setValue(startDate);
      this.searchByRangeEndDateCtrl.setValue(endDate);
    }
  }

  // Find the CIS submission in the datasource array.
  _findCisSubmission(atsTestDefinitionId: number): AccommodationInstructions {

    const entries: AccommodationInstructions[] = this.dataSource.data;

    const val = entries.find(value => value.atsTestDefinitionId === atsTestDefinitionId);

    return !val ? null : val;
  }

  _getStatisticsData() {
    const statistics = new Statistics();

    const cisSubmissions: AccommodationInstructions[] = this.dataSource.data;

    statistics.rangeStartDate = this.searchByRangeStartDateCtrl.value;
    statistics.rangeEndDate = this.searchByRangeEndDateCtrl.value;

    statistics.totalTestDefsInCIS = this.numberOfCisAccommInstructions;
    statistics.notSubmittedInCis = this.numberOfCisAccommInstructions - (cisSubmissions?.length || 0);
    statistics.reviewed = 0;
    statistics.reviewedInstructorUpdated = 0;
    statistics.pendingConfirmation = 0;
    statistics.pendingConfirmationAwaitingFile = 0;
    statistics.pendingConfirmationInstructorUpdated = 0;
    statistics.notReviewed = 0;

    cisSubmissions.forEach((submission: AccommodationInstructions) => {

      if (submission._reviewStatus === this.ReviewStatusCode.REVIEWED) {
        ++statistics.reviewed;

      } else if (submission._reviewStatus === this.ReviewStatusCode.REVIEWED_INSTRUCTOR_UPDATED) {
        ++statistics.reviewedInstructorUpdated;

      } else if (submission._reviewStatus === this.ReviewStatusCode.PENDING_CONFIRMATION) {
        ++statistics.pendingConfirmation;

      } else if (submission._reviewStatus === this.ReviewStatusCode.PENDING_CONFIRMATION_AWAITING_FILE) {
        ++statistics.pendingConfirmationAwaitingFile;

      } else if (submission._reviewStatus === this.ReviewStatusCode.PENDING_CONFIRMATION_INSTRUCTOR_UPDATED) {
        ++statistics.pendingConfirmationInstructorUpdated;

      } else if (submission._reviewStatus === this.ReviewStatusCode.NOT_REVIEWED
                   || submission._reviewStatus === null) {
        ++statistics.notReviewed;

      }
    })

    return statistics;
  }

  // Open a modal
  _openModal(): NgbModalRef {
    return this.modalService.open(ViewStatisticsModalComponent, {size: 'lg'});
  }

  // Assigns the logged-in user as a reviewer
  assignReviewer(atsTestDefId: number) {

    const cisSubmission: AccommodationInstructions = this._findCisSubmission(atsTestDefId);

    // Do the back-end call
    this.model.assignReviewer(
      { atsTestDefId: atsTestDefId,
        prevReviewer: cisSubmission._reviewer,
        reviewer: this.loggedInUser }
    ).subscribe(() => {


      // Assign reviewer
      cisSubmission._reviewer = this.loggedInUser;

      // Show success toast.
      const msg = `You have been assigned to the CIS Submission for ${this.Utils.formatCourse(cisSubmission)}
                                                                             (Test Definition ID: ${atsTestDefId}).`

      this.toastService.show({type: 'success', action: msg});
    });
  }

  // Assigns the logged-in user as a reviewer
  unassignReviewer(atsTestDefId: number) {

    const cisSubmission: AccommodationInstructions = this._findCisSubmission(atsTestDefId);

    // Do the back-end call
    this.model.unassignReviewer(
      { atsTestDefId: atsTestDefId,
        prevReviewer: cisSubmission._reviewer,
        reviewer: '' }
    ).subscribe(() => {

      // Unassign reviewer
      cisSubmission._reviewer = '';

      // Show success toast
      const msg = `You have been unassigned from the CIS Submission for ${this.Utils.formatCourse(cisSubmission)}
                                                                             (Test Definition ID: ${atsTestDefId}).`;
      this.toastService.show({type: 'success', action: msg});
    });
  }

  /** Updates ATS test definition date with the CIS instructor date. */
  updateTestDefDate(atsTestDefinitionId: number,
                    instrStartDate: string,
                    assessmentTimingInd: string): void {

    const momentObj: moment.Moment = moment(instrStartDate);
    const testDefDate: TestDefDateTime = new TestDefDateTime(atsTestDefinitionId, momentObj.toDate(), null, null);
    testDefDate.assessmentTimingInd = assessmentTimingInd;

    this.model.updateTestDefDate(testDefDate).subscribe(() => {

      // Find the test definition and update the date.
      const accomm: AccommodationInstructions = this._findCisSubmission(atsTestDefinitionId);
      accomm._studInstrChanges.studStartDate = accomm._studInstrChanges.instrStartDate;
      // - set differentDates property to false; that will remove the question mark button from the dashboard.
      accomm._studInstrChanges.differentDates = false;

      // Show success toast
      const msg = `You have updated the Test Definition Class Writing Date to ${momentObj.format('YYYY-MM-DD')}.`;
      this.toastService.show({type: 'success', action: msg});
    });
  }

  /** Updates ATS test definition start time with the CIS instructor start time. */
  updateTestDefStartTime(atsTestDefinitionId: number,
                         instrStartTime12Hour: string,
                         assessmentTimingInd: string): void {

    // Convert time from 12-hour format to 24-hour format (on the back-end will be automatically converted to LocalTime).
    const momentObj: moment.Moment = moment(instrStartTime12Hour, 'hh:mm A');
    const instrStartTime = momentObj.format('HH:mm');

    const testDefTime: TestDefDateTime = new TestDefDateTime(atsTestDefinitionId, null, instrStartTime, null);
    testDefTime.assessmentTimingInd = assessmentTimingInd;

    this._updateTestDefTime(atsTestDefinitionId, testDefTime, momentObj);
  }

  /** Updates ATS test definition end time with the CIS instructor end time. */
  updateTestDefEndTime(atsTestDefinitionId: number,
                       instrEndTime12Hour: string,
                       assessmentTimingInd: string) {

    // Convert time from 12-hour format to 24-hour format (on the back-end will be automatically converted to LocalTime).
    const momentObj: moment.Moment = moment(instrEndTime12Hour, 'hh:mm A');
    const instrEndTime = momentObj.format('HH:mm');

    // Time, to be sent to the back-end, has to be in 24-hour format.
    const testDefTime: TestDefDateTime = new TestDefDateTime(atsTestDefinitionId, null, null, instrEndTime);
    testDefTime.assessmentTimingInd = assessmentTimingInd;

    this._updateTestDefTime(atsTestDefinitionId, testDefTime, momentObj);

  }

  /**
   * Call the back-end REST API to update the test definition start or end time with the instructor one.
   * NOTE: TestDefDateTime object will always have set either start time or end time but never both.
   * @param atsTestDefinitionId The test definition ID.
   * @param testDefTime         Object holding instructor's start time or end time.
   * @param momentObj           A Moment object holding the time; used to convert between 12 and 24 hours time.
   */
  _updateTestDefTime(atsTestDefinitionId: number, testDefTime: TestDefDateTime, momentObj: moment.Moment) {

    this.model.updateTestDefTime(testDefTime).subscribe(() => {

      let msg = '';

      // Find the test definition and update the time.
      const accomm: AccommodationInstructions = this._findCisSubmission(atsTestDefinitionId);

      if (testDefTime.startTime) {
        // Update start time on CIS Submission
        accomm._studInstrChanges.studStartTime = accomm._studInstrChanges.instrStartTime;
        // - set differentTimes property to false; that will remove the question mark button from the dashboard.
        accomm._studInstrChanges.differentStartTimes = false;

        msg = `You have updated the Test Definition Class Writing Start Time to ${momentObj.format('h:mm a').toUpperCase()}.`;
      }

      if (testDefTime.endTime) {
        // Update end time on CIS Submission
        accomm._studInstrChanges.studEndTime = accomm._studInstrChanges.instrEndTime;
        // - set differentTimes property to false; that will remove the question mark button from the dashboard.
        accomm._studInstrChanges.differentEndTimes = false;

        msg = `You have updated the Test Definition Class Writing End Time to ${momentObj.format('h:mm a').toUpperCase()}.`;
      }

      // Show success toast
      this.toastService.show({type: 'success', action: msg});
    });
  }

  /** Triggers a back-end search for the CIS submissions, based on the search start and end dates. */
  search() {
    if (this.searchByRangeStartDateCtrl.value
          && this.searchByRangeEndDateCtrl.value) {

      this.model.getCisSubmissions(this.searchByRangeStartDateCtrl.value, this.searchByRangeEndDateCtrl.value)
        .subscribe((val: CisSubmissions) => {

          this.dataSource = new MatTableDataSource<AccommodationInstructions>(val.cisSubmissions);
          this.numberOfCisAccommInstructions = val.numberOfCisAccommInstructions;
          this.loggedInUser = val.loggedInUser;

          this._sortCisSubmissions();

          this.dataSource.paginator = this.paginator;
        });
    }
  }

  /** Enable sorting of the CIS Submission table. */
  _sortCisSubmissions() {

    this.dataSource.sortingDataAccessor = (data: AccommodationInstructions, sortHeaderId: string): string => {

      let columnVal: string;

      switch (sortHeaderId) {
        case 'atsAssignee':
          columnVal = data._reviewer;
          break;
        case 'reviewedStatus':
          columnVal = data._reviewStatus;
          break;
        case 'classWritingDate':
          columnVal = this.Utils.formatClassWritingDate(data.assessmentStartDate);
          break;
        case 'classWritingStartTime':
          columnVal = this.Utils.formatClassWritingTime(data.assessmentStartTime);
          break;
        case 'classWritingEndTime':
          columnVal = this.Utils.formatClassWritingTime(data.assessmentEndTime);
          break;
        case 'duration':
          columnVal = !data.assessmentDuration ? '' : data.assessmentDuration.toString();
          break;
        case 'courseSection':
          columnVal = this.Utils.formatAccommodationInstructions(data);
          break;
        case 'type':
          columnVal = `${data.testSubtype}${data.testType}`;
          break;
        case 'onlineVsInPerson':
          columnVal = this.Utils.onlineVsInPersonMap.get(data.__onlineVsInPerson) || 'N/A';
          break;
        case 'quercusConsent':
          columnVal = this._convertBooleanToStr(data.quercusPermissionToAtsInd);
          break;
        case 'testDefinitionId':
          columnVal = this._convertNumberToStr(data.atsTestDefinitionId);
          break;
        case 'numberOfBookings':
          columnVal = this._convertNumberToStr(data.numberOfBookings);
          break;
        case 'assessmentTimingType':
          columnVal = data.assessmentTimingInd;
          break;
        case 'lectureBeforeOfAfter':
          columnVal = data.lecBeforeOrAfterAssessmentInd;
          break;
        case 'groupComponent':
          columnVal = this._convertBooleanToStr(data.groupComponentForAssessmentInd);
          break;
        case 'avCompRequired':
          columnVal = this._convertBooleanToStr(data.audiovisualRequiredInd);
          break;
        case 'testMaterialDeliveryMethod':
          columnVal = data.deliveryInstruction;
          break;
        case 'fileUploaded':
          columnVal = this._convertBooleanToStr(data.requiresFileUpload);
          break;
        case 'cisSubmissionStatus':
          columnVal = data.workflowStatus.statusCode;
          break;
        default:
          columnVal = '';
      }

      return columnVal;
    }

    this.dataSource.sort = this.sort;
  }

  _convertBooleanToStr(val: boolean): string {
    return val === true ? 'Yes' : (val === false ? 'No' : 'N/A');
  }

  _convertNumberToStr(val: number): string {
    return !val ? '0' : val.toString();
  }

  canUnassign(reviewer: string, reviewStatus: string): boolean {
    return (reviewer === this.loggedInUser
                && (reviewStatus === ReviewStatusCode.NOT_REVIEWED || !reviewStatus));
  }

  /** Removes the set start and end class writing dates. */
  resetSearch() {
    this.searchByRangeStartDateCtrl.setValue('');
    this.searchByRangeEndDateCtrl.setValue('');
  }

  /** Triggers a popup modal for showing the statistics. */
  viewStatistics() {
    let modalRef = this._openModal();

    // Set data to be shown in modal
    modalRef.componentInstance.statistics = this._getStatisticsData();
  }

  /**
   * Based on the ATS test definition ID, get the CIS submission
   * from the datasource array and send it to the 'details' page.
   * */
  reviewDetails(atsTestDefinitionId: number) {
    this.logger.debug(`=> Review Details for CIS submission with atsTestDefinitionId: ${atsTestDefinitionId}`,
                                     `${this.constructor.name}`);

    const cisSubmission = this._findCisSubmission(atsTestDefinitionId);

    let navigationExtras: NavigationExtras = {
      state: {
        cisSubmission: cisSubmission,
        loggedInUser: this.loggedInUser,
        accommodationChoices: this.accommodationChoices
      }
    };

    this.router.navigate([`/cis-submissions/${atsTestDefinitionId}`], navigationExtras)
      .then(nav => {}, err => {this.logger.error("Navigation failed with error: " + err, `${this.constructor.name}`)});
  }

  tableDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayColumns, event.previousIndex, event.currentIndex);
  }

  _storeUserDisplayColumns(): void {
    this.localStorageService.set('userDisplayColumns', this.displayColumns);
  }

  _retrieveUserDisplayColumns(): void {
    if (this.localStorageService.get('userDisplayColumns')) {
      this.displayColumns = this.localStorageService.get('userDisplayColumns');
    }
  }

  protected readonly Utils = CisSubmissionsUtils;
  protected readonly CommonUtils = CommonUtils;
  protected readonly ReviewStatusCode = ReviewStatusCode;
}
