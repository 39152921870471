<div class="row">
  <div class="col-3">
    <label class="form-label" for="dateOption">{{selectDateLabel}}</label>
    <select id="dateOption" class="form-select"
            [formControl]="dateOption"
            (change)="changeDateOption()">
      <option *ngFor="let dateOption of dateOptions" [ngValue]="dateOption">{{dateOption.description}}</option>
    </select>
  </div>
  <div class="col-3" >
    <label class="form-label" for="startDate">Date Range Start</label>
    <div id="startDate">
      <app-rf-datepicker [formControl]="startDate"></app-rf-datepicker>
    </div>
  </div>
  <div class="col-3">
    <label class="form-label" for="startDate">Date Range End</label>
    <div id="endDate">
      <app-rf-datepicker [formControl]="endDate" ></app-rf-datepicker>
    </div>
  </div>
</div>

<div class="row margin-top-2-rem align-items-center">
  <div class="col-2">
    <label class="form-label" for="course">Course</label>
  </div>
  <div class="col-1">
    <label class="form-label" for="sectionCode">Section Code</label>
  </div>
  <div class="col-1">
    <label class="form-label" for="teachingMethod">Teaching Method</label>
  </div>
  <div class="col-1">
    <label class="form-label" for="sectionNr">Section Number</label>
  </div>
  <div class="col-1" *ngIf="studentIdNeeded">
    <label class="form-label" for="studentId">Student Number</label>
  </div>
  <div class="col-1 bottom-align">&nbsp;
  </div>
  <div class="col-1 bottom-align">&nbsp;
  </div>
</div>

<div class="row align-items-top align-items-center">
  <div class="col-2">
    <input id="course" class="form-control" type="text" [formControl]="course" placeholder="E.g: AST101H1">
  </div>
  <div class="col-1">
    <input id="sectionCode" class="form-control" type="text" [formControl]="sectionCode" placeholder="E.g: F, S, Y">
  </div>
  <div class="col-1">
    <input id="teachingMethod" class="form-control" type="text" [formControl]="teachingMethod" placeholder="E.g: LEC">
  </div>
  <div class="col-1">
    <input id="sectionNr" class="form-control" type="text" [formControl]="sectionNr" placeholder="E.g: 5101">
  </div>
  <div class="col-1" *ngIf="studentIdNeeded">
    <input id="studentId" class="form-control" type="text" [formControl]="studentId">
  </div>

  <div class="row margin-top-2-rem">
    <!-- Search buttons -->
    <div class="col-12">
      <!-- Search button -->
      <span>
        <sis-button visualType="primary" size="medium" (clicked)="sendOutPeriod()">Search</sis-button>
      </span>
      <!-- Reset Search button -->
      <span class="margin-left-2rem">
        <sis-button visualType="secondary" size="medium" (clicked)="reset()">Reset Search</sis-button>
      </span>
    </div>
  </div>

</div>
