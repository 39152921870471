import {Inject, Injectable} from '@angular/core';
import {DATA_SOURCE, DataSource} from './data-sources/data-source';
import {StudentInfo} from './types/studentInfo';
import {StudentBooking} from './types/student-booking';
import {ActivityLog} from './types/activityLog';
import {Observable} from 'rxjs';
import {Person} from './types/person';
import {Bookings} from './types/bookings';
import {PrintTestMaterial} from "./types/print-test-material";
import {AccommodationCategory} from './types/accommodation-category';
import {Accommodation} from './types/accommodation';
import {Course} from "./types/course";
import {StudentAccommodations} from './types/student-accommodations';
import {LogService} from '../common/services/log-service.service';
import {ManualBooking} from "./types/manual-booking";
import {SelectedTestDef} from "./types/selected-test-def";
import {AccommodationConflict} from "./types/accommodation-conflict";
import {StudentName} from './types/student-name';
import {StudentSearch} from './types/student-search';
import {StaffNotes} from "./types/staff-notes";
import {AccommodationInstructions} from "./types/accommodation-instructions";
import {AccommodationChoices} from "./types/accommodation-choices";
import {EditBookingDetails} from "./types/edit-booking-details";
import {DesignateAssignment} from "./types/designate-assignment";
import {ActivityOfferOrg} from "./types/activity-offer-org";
import {Room} from "./types/room";
import {Designate} from "./types/designate";
import {TestType} from "./types/testType";
import {SpaceOrOnline} from "./types/space-or-online";
import {SingleDesignateAssignment} from "./types/single-designate-assignment";
import {AccommodationSearch} from "./types/accommodation-search";
import {StudentCourseAccommodations} from "./types/student-course-accommodations";
import {TestDefinitions} from "./types/test-definitions";
import {CourseBookings} from "./types/course-bookings";
import {TestDef} from "./types/test-def";
import {FileUploadResp} from "./types/file-upload-resp";
import {PeriodCourseSelector} from "./types/periodCourseSelector";
import {SimpleBooking} from "./types/simple-booking";
import {UpdatedBookingStatus} from "./types/updated-booking-status";
import {CourseContacts} from "./types/course-contacts";
import {CourseByLevelOfInstr} from "./types/course-by-level-of-instr";
import {AtsResp} from "./types/ats-resp";
import {InstructorEmailContacts} from "../instructor-emailing/instructor-email-contacts";
import {SelectedEmailContacts} from "../instructor-emailing/selected-email-contacts";
import {NotificationLog} from "../instructor-emailing/notification-log";
import {EmailResp} from "../instructor-emailing/email-resp";
import {ReviewStatus} from "../cis-submissions/review-status";
import {CisSubmissions} from "../cis-submissions/cis-submissions";
import {AtsNote} from "../cis-submissions/ats-note";
import {Reviewer} from "../cis-submissions/reviewer";
import {TestDefDateTime} from "../cis-submissions/test-def-date-time";

/**
 * Service: ModelService
 * Desc:
 *    This service should contain all the data CRUD operations.
 *    It is similar to the DAO layer in Java back-end.
 *    Can do data manipulation, conversion, caching,...
 */

@Injectable({
  providedIn: 'root'
})
export class ModelService {

  studentBooking: StudentBooking;
  activityLogs: ActivityLog[] = [];
  advisors: Person[] = [];
  printTestMaterials: PrintTestMaterial[] = [];
  accommodationCategories: AccommodationCategory[];

  constructor(@Inject(DATA_SOURCE) private dataSource: DataSource, private logger: LogService) {}

  getTodayBookings(): Observable<Bookings> {
    return this.dataSource.getTodayBookings();
  }

  getTodayTestDefinitions(): Observable<TestDefinitions> {
    return this.dataSource.getTodayTestDefinitions();
  }

  getStudentBooking(id: number) {
    return this.dataSource.getStudentBooking(id);
  }

  getSelectedStudentBooking(): StudentBooking {
    return this.studentBooking;
  }

  getActivityLogsForTestBooking(testBookingId: number): Observable<ActivityLog[]> {
    return this.dataSource.getActivityLogsForTestBooking(testBookingId);
  }

  getActivityLogsForTestDefinition(testDefinitionId: number): Observable<ActivityLog[]> {
    return this.dataSource.getActivityLogsForTestDefinition(testDefinitionId);
  }

  getAdvisors(): Person[] {
    return this.advisors;
  }

  getTestMaterials(): Observable<PrintTestMaterial[]> {
    return this.dataSource.getPrintTestMaterials();
  }

  getAccommodations(): Observable<Accommodation[]> {
    return this.dataSource.getAccommodations();
  }

  getStudentCourses(params: StudentSearch): Observable<Course[]> {
    return this.dataSource.getStudentCourses(params);
  }

  getCourseSections(courseCode: string, testDefSubtype: string): Observable<Course[]> {
    return this.dataSource.getCourseSections(courseCode, testDefSubtype);
  }

  getStudentInfo(atsStudentId: string): Observable<StudentInfo> {
    return this.dataSource.getStudentInfo(atsStudentId);
  }

  getAccommodationChoices(): Observable<AccommodationChoices> {
    return this.dataSource.getAccommodationChoices();
  }

  getAccommodatedStudentNames(searchVal: string): Observable<StudentName[]> {
    return this.dataSource.getAccommodatedStudentNames(searchVal);
  }

  createTestDefinitions(booking: ManualBooking) {
     return this.dataSource.createTestDefinitions(booking);
  }

  getAccommodationsByStudentId(studentId: string): Observable<StudentAccommodations> {
    return  this.dataSource.getAccommodationsByStudentId(studentId);
  }

  getAccommodationsByStudentAndCourse(accommodationSearch: AccommodationSearch): Observable<StudentCourseAccommodations> {
    return  this.dataSource.getAccommodationsByStudentAndCourse(accommodationSearch);
  }

  getCourseSpecificAccommodationsByBookingId(bookingId: number): Observable<StudentCourseAccommodations> {
    return  this.dataSource.getCourseSpecificAccommodationsByBookingId(bookingId);
  }

  updateAccommodationsFromClockwork(rosiStudentNumber: string) {
    return this.dataSource.updateAccommodationsFromClockwork(rosiStudentNumber);
  }

  getActiveTestDefinitions(courseCode: string, sectionCode: string, sessionCode: string,
                           teachMethod: string, sectionNr: string, testType: string): Observable<SelectedTestDef[]> {
    return this.dataSource.getActiveTestDefinitions(courseCode, sectionCode, sessionCode, teachMethod, sectionNr, testType);
  }

  getRosiCourseInstructors(courseCode: string, sectionCode: string, sessionCode: string,
                           teachMethod: string, sectionNr: string): Observable<any> {
    return this.dataSource.getRosiCourseInstructors(courseCode, sectionCode, sessionCode, teachMethod, sectionNr);
  }

  getAccommodationConflicts(bookingId: string): Observable<AccommodationConflict[]> {
    return this.dataSource.getAccommodationConflicts(bookingId);
  }

  getBookingsByStudentId(studentId: string): Observable<Bookings> {
    return this.dataSource.getBookingsByStudentId(studentId);
  }


  getBookingsGroupsByStudentId(studentId: string): Observable<CourseBookings[]> {
    return this.dataSource.getBookingsGroupsByStudentId(studentId);
  }

  saveStaffNotes(staffNotes: StaffNotes): Observable<ActivityLog[]> {
    return this.dataSource.saveStaffNotes(staffNotes);
  }

  getTestDetails(testBookingId: string): Observable<AccommodationInstructions> {
    return this.dataSource.getTestDetails(testBookingId);
  }

  updateStudentBooking(updatedBookingDetails: EditBookingDetails) {
    return this.dataSource.updateStudentBooking(updatedBookingDetails);
  }

  getDesignateAssignments(assignmentLevel: string): Observable<DesignateAssignment[]> {
    return this.dataSource.getDesignateAssignments(assignmentLevel);
  }

  saveDesignateAssignment(designates: DesignateAssignment) {
    return this.dataSource.saveDesignateAssignment(designates);
  }

  deleteDesignateAssignment(designateBindingId: string, assignmentLevel: string) {
    return this.dataSource.deleteDesignateAssignment(designateBindingId, assignmentLevel);
  }

  getActivityOfferOrgs(): Observable<ActivityOfferOrg[]> {
    return this.dataSource.getActivityOfferOrgs();
  }

  getRosiCourses(searchVal: string): Observable<Course[]> {
    return this.dataSource.getRosiCourses(searchVal);
  }

  validateDesignate(utorIdOrEmail: string): Observable<Designate> {
    return this.dataSource.validateDesignate(utorIdOrEmail);
  }

  getRooms(): Observable<Room[]> {
    return this.dataSource.getRooms();
  }

  getBookingsByDateRange(startEndDate: string[]): Observable<Bookings> {
    return this.dataSource.getBookingsByDateRange(startEndDate);
  }


  getTestDefinitionsByDateRange(startEndDate: string[]): Observable<TestDefinitions> {
    return this.dataSource.getTestDefinitionsByDateRange(startEndDate);
  }

  getTestDefinitionsByDate(assessmentDate: string): Observable<TestDefinitions> {
    return this.dataSource.getTestDefinitionsByDate(assessmentDate);
  }

  getInstrEmailingTestDefs(assessmentDate: string): Observable<TestDefinitions> {
    return this.dataSource.getInstrEmailingTestDefs(assessmentDate);
  }

  findAllCourseContacts(testDefId: string): Observable<InstructorEmailContacts> {
    return this.dataSource.findAllCourseContacts(testDefId);
  }

  sendEmail(selectedContacts: SelectedEmailContacts): Observable<EmailResp> {
    return this.dataSource.sendEmail(selectedContacts);
  }

  getNotificationLog(testDefId: number): Observable<NotificationLog[]> {
    return this.dataSource.getNotificationLog(testDefId);
  }

  getTestTypes(): Observable<TestType[]> {
    return this.dataSource.getTestTypes();
  }

  updateBookingSpace(selectedSpace: SpaceOrOnline): Observable<SpaceOrOnline> {

    return this.dataSource.updateBookingSpace(selectedSpace);
  }

  convertDesignateAssignmentsToSingleDesignateAssignments(designateAssignments: DesignateAssignment[]): SingleDesignateAssignment[] {
    if (!designateAssignments) { return null; }
    const singleDesignateAssignments: SingleDesignateAssignment[] = [];
    designateAssignments.forEach(dA => {
      if (dA.designates) {
        dA.designates.forEach(designate => {
          singleDesignateAssignments.push(new SingleDesignateAssignment(dA.assignmentLevel, dA.course, dA.coursePrefix, dA.org, designate));
        });
      }
    });

    return singleDesignateAssignments;
  }

  markAsException(bookingValidationId: number) {
    return this.dataSource.markAsException(bookingValidationId);
  }

  undoMarkConflictAsException(bookingValidationId: number) {
    return this.dataSource.undoMarkConflictAsException(bookingValidationId);
  }

  provisionStudent(rosiStudentNumber: number): Observable<any> {
    return this.dataSource.provisionStudent(rosiStudentNumber);
  }

  getVersion(): Observable<any> {
    return this.dataSource.getVersion();
  }

  uploadExams(file: any): Observable<FileUploadResp> {
    return this.dataSource.uploadExams(file);
  }

  getCourses(coursePrefix: string): Observable<Course[]> {
    return this.dataSource.getCourses(coursePrefix);
  }

  createTestDef(testDef: TestDef) {
    return this.dataSource.createTestDef(testDef);
  }

  getTestDef(testDefId: number): Observable<TestDef> {
    return this.dataSource.getTestDef(testDefId);
  }

  getTestDefByExamId(examId: number): Observable<TestDef> {
    return this.dataSource.getTestDefByExamId(examId);
  }

  updateTestDef(testDef: TestDef) {
    return this.dataSource.updateTestDef(testDef);
  }

  updateTestDefWithTypes(testDef: TestDef) {
    return this.dataSource.updateTestDefWithTypes(testDef);
  }

  getTestDefinitions(selector: PeriodCourseSelector): Observable<TestDefinitions> {
    return this.dataSource.getTestDefinitions( selector);
  }

  getBookings(selector: PeriodCourseSelector): Observable<Bookings> {
    return this.dataSource.getBookings(selector);
  }

  getBookingsByTestDefinitionId(testDefinitionId: number): Observable<SimpleBooking[]> {
    return this.dataSource.getBookingsByTestDefinitionId(testDefinitionId);
  }

  moveBookingsToOtherTestDefinitions(bookings: SimpleBooking[]): Observable<any> {
    return this.dataSource.moveBookingsToOtherTestDefinitions(bookings);
  }

  updateBookingStatus(bookingStatus: UpdatedBookingStatus): Observable<string> {
    return this.dataSource.updateBookingStatus(bookingStatus);
  }

  exportTestDefToClockwork(testDefinitionId: number): Observable<number> {
    return this.dataSource.exportTestDefToClockwork(testDefinitionId);
  }

  deleteTestDef(testDefinitionId: number): Observable<string> {
    return this.dataSource.deleteTestDef(testDefinitionId);
  }

  // -- Instructor emailing --
  saveCourseContacts(courseContacts: CourseContacts, editContacts: boolean): Observable<AtsResp> {
    return this.dataSource.saveCourseContacts(courseContacts, editContacts);
  }

  getCourseContacts(courseContacts: CourseContacts): Observable<CourseContacts> {
    return this.dataSource.getCourseContacts(courseContacts);
  }

  deleteCourseContacts(courseContacts: CourseContacts): Observable<any> {
    return this.dataSource.deleteCourseContacts(courseContacts);
  }

  getCourseContactList(): Observable<CourseContacts[]> {
    return this.dataSource.getCourseContactList();
  }

  getCoursePrefByLevelOfInstr(): Observable<CourseByLevelOfInstr> {
    return this.dataSource.getCoursePrefByLevelOfInstr();
  }

  getCourseByLevelOfInstr(courseCodePrefix: string): Observable<CourseByLevelOfInstr> {
    return this.dataSource.getCourseByLevelOfInstr(courseCodePrefix);
  }

  getCourseMeetingSecByLevelOfInstr(courseCodePrefix: string): Observable<CourseByLevelOfInstr> {
    return this.dataSource.getCourseMeetingSecByLevelOfInstr(courseCodePrefix);
  }

  getCisSubmissions(startDate: string, endDate: string): Observable<CisSubmissions> {
    return this.dataSource.getCisSubmissions(startDate, endDate);
  }

  assignReviewer(reviewer: Reviewer): Observable<any> {
    return this.dataSource.assignReviewer(reviewer);
  }

  unassignReviewer(reviewer: Reviewer): Observable<any> {
    return this.dataSource.unassignReviewer(reviewer);
  }

  updateTestDefDate(testDefDate: TestDefDateTime): Observable<any> {
    return this.dataSource.updateTestDefDate(testDefDate);
  }

  updateTestDefTime(testDefDate: TestDefDateTime): Observable<any> {
    return this.dataSource.updateTestDefTime(testDefDate);
  }

  setReviewStatus(reviewStatus: ReviewStatus): Observable<any> {
    return this.dataSource.setReviewStatus(reviewStatus);
  }

  addAtsNoteForCisSubmission(atsNote: AtsNote): Observable<any> {
    return this.dataSource.addAtsNoteForCisSubmission(atsNote);
  }

  getAtsNotesForCisSubmission(atsTestDefId: number): Observable<AtsNote[]> {
    return this.dataSource.getAtsNotesForCisSubmission(atsTestDefId);
  }

}
