import {Component, Input} from '@angular/core';
import {ActivityLog} from "../../../model/types/activityLog";
import {Observable} from "rxjs";

@Component({
  selector: 'app-activity-log-table',
  templateUrl: './activity-log-table.component.html',
  styleUrls: ['./activity-log-table.component.scss']
})
export class ActivityLogTableComponent {

  @Input()
  logs$: Observable<ActivityLog[]>;
}
